import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import DifferenceReason from "@/types/DifferenceReason";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface DifferenceReasonInfo {
  differenceReason: DifferenceReason;
}

@Module
export default class DifferenceReasonModule extends VuexModule implements DifferenceReasonInfo {
  differenceReason = {} as DifferenceReason;

  @Mutation
  [Mutations.SET_DIFFERENCE_REASON](differenceReason) {
    this.differenceReason = differenceReason;
  }

  @Action
  [Actions.DIFFERENCE_REASON_LIST]() {
    return ApiService.get("DifferenceReason")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DIFFERENCE_REASON, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_DIFFERENCE_REASON](args) {
    return ApiService.post("DifferenceReason", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_DIFFERENCE_REASON](args) {
    return ApiService.put("DifferenceReason", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_DIFFERENCE_REASON](id) {
    return ApiService.delete("DifferenceReason/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
