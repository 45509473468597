import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Account from "@/types/Account";
import Role from "@/types/Role";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AccountInfo {
  account: Account;
  role: Role;
}

@Module
export default class AccountModule extends VuexModule implements AccountInfo {
  account = {} as Account;
  role = {} as Role;

  @Mutation
  [Mutations.SET_ACCOUNT](account) {
    this.account = account;
  }

  @Mutation
  [Mutations.SET_ROLE](role) {
    this.role = role;
  }

  @Action
  [Actions.ROLE_LIST]() {
    return ApiService.get("role")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ACCOUNT_LIST]() {
    return ApiService.get("Account")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ACCOUNT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_ACCOUNT](args) {
    return ApiService.post("Account", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_ACCOUNT](args) {
    return ApiService.put("Account", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.CHANGE_PASSWORD](args) {
    return ApiService.put("Account/change-password", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_ACCOUNT](id) {
    return ApiService.delete("Account/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
