import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Supplier from "@/types/Supplier";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SupplierInfo {
  supplier: Supplier;
}

@Module
export default class SupplierModule extends VuexModule implements SupplierInfo {
  supplier = {} as Supplier;

  @Mutation
  [Mutations.SET_SUPPLIER](supplier) {
    this.supplier = supplier;
  }

  @Action
  [Actions.SUPPLIER_LIST]() {
    return ApiService.get("Supplier")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUPPLIER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_SUPPLIER](args) {
    return ApiService.post("Supplier", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_SUPPLIER](args) {
    return ApiService.put("Supplier", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_SUPPLIER](id) {
    return ApiService.delete("Supplier/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
