import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import OrderType from "@/types/OrderType";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface OrderTypeInfo {
  orderType: OrderType;
}

@Module
export default class OrderTypeModule extends VuexModule implements OrderTypeInfo {
  orderType = {} as OrderType;

  @Mutation
  [Mutations.SET_ORDERTYPE](orderType) {
    this.orderType = orderType;
  }

  @Action
  [Actions.ORDERTYPE_LIST]() {
    return ApiService.get("orderType")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDERTYPE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_ORDERTYPE](args) {
    return ApiService.post("orderType", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_ORDERTYPE](args) {
    return ApiService.put("orderType", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_ORDERTYPE](id) {
    return ApiService.delete("orderType/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
