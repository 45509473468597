import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface RoleInfo {
  role: any;  
  roleScreen: any;
}
@Module
export default class RoleModule extends VuexModule implements RoleInfo {
  
  role = {} as any;
  roleScreen = {} as any;
  @Mutation
  [Mutations.SET_ROLE](role) {
    this.role = role;
  }
  @Mutation
  [Mutations.SET_ROLE_SCREEN](roleScreen) {
    this.roleScreen = roleScreen;
  }

  @Action
  [Actions.ROLE_LIST]() {
    return ApiService.get("role")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ROLE_SCREEN_LIST](roleId) {
    return ApiService.get("role/screen?roleId="+roleId+"")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLE_SCREEN, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_ROLE_SCREEN](args) {
    return ApiService.post("role/screen", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.ADD_ROLE](args) {
    return ApiService.post("role", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }
  
  }

