import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Department from "@/types/Department";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface DepartmentInfo {
  department: Department;
}

@Module
export default class DepartmentModule extends VuexModule implements DepartmentInfo {
  department = {} as Department;

  @Mutation
  [Mutations.SET_DEPARTMENT](department) {
    this.department = department;
  }

  @Action
  [Actions.DEPARTMENT_LIST]() {
    return ApiService.get("Department")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEPARTMENT, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_DEPARTMENT](args) {
    return ApiService.post("Department", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_DEPARTMENT](args) {
    return ApiService.put("Department", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_DEPARTMENT](id) {
    return ApiService.delete("Department/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
