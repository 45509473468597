import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import SizeSet from "@/types/SizeSet";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SizeSetInfo {
  sizeSet: SizeSet;
}

@Module
export default class SizeSetModule extends VuexModule implements SizeSetInfo {
  sizeSet = {} as SizeSet;

  @Mutation
  [Mutations.SET_SIZE_SET](sizeSet) {
    this.sizeSet = sizeSet;
  }

  @Action
  [Actions.SIZE_SET_LIST]() {
    return ApiService.get("size")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_SIZE_SET](args) {
    return ApiService.post("size/set/add", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_SIZE_SET](args) {
    return ApiService.put("size/set/update", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_SIZE_SET](id) {
    return ApiService.delete("size/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

  @Action
  [Actions.REMOVE_SIZE](id) {
    return ApiService.delete("size/" + id + "/size")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
