import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LogInfo {
  transactionLog: any;
}

@Module
export default class LogModule extends VuexModule implements LogInfo {
  transactionLog = {} as any;

  @Mutation
  [Mutations.SET_TRANSACTION_LOG](transactionLog) {
    this.transactionLog = transactionLog;
  }

  @Action
  [Actions.TRANSACTİON_LOG_LIST]() {
    return ApiService.get("AppInformation")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TRANSACTION_LOG, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

}
