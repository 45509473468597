import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Province from "@/types/Province";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface ProvinceInfo {
  province: Province;
}

@Module
export default class ProvinceModule extends VuexModule implements ProvinceInfo {
  province = {} as Province;

  @Mutation
  [Mutations.SET_PROVINCE](province) {
    this.province = province;
  }

  @Action
  [Actions.PROVINCE_LIST]() {
    return ApiService.get("province")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  

}