enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  PROVINCE_LIST = "provinceList",
  MY_PROFILE = "myProfile",

  SIZE_SET_LIST = "sizeSetList",
  ADD_SIZE_SET = "addSizeSet",
  UPDATE_SIZE_SET = "updateSizeSet",
  REMOVE_SIZE_SET = "removeSizeSet",
  REMOVE_SIZE = "removeSize",
  DIFFERENCE_REASON_LIST = "differenceReasonList",
  ADD_DIFFERENCE_REASON = "addDifferenceReason",
  UPDATE_DIFFERENCE_REASON = "updateDifferenceReason",
  REMOVE_DIFFERENCE_REASON = "removeDifferenceReason",
  CUSTOMER_LIST = "customerList",
  ADD_CUSTOMER = "addCustomer",
  UPDATE_CUSTOMER = "updateCustomer",
  REMOVE_CUSTOMER = "removeCustomer",
  BRAND_LIST = "brandList",
  ADD_BRAND = "addBrand",
  UPDATE_BRAND = "updateBrand",
  REMOVE_BRAND = "removeBrand",
  ORDERTYPE_LIST = "orderTypeList",
  ADD_ORDERTYPE = "addorderType",
  UPDATE_ORDERTYPE = "updateorderType",
  REMOVE_ORDERTYPE = "removeorderType",

  SHIPPINGTYPE_LIST = "shippingTypeList",
  ADD_SHIPPINGTYPE = "addshippingType",
  UPDATE_SHIPPINGTYPE = "updateshippingType",
  REMOVE_SHIPPINGTYPE = "removeshippingType",

  DEPARTMENT_LIST = "departmentList",
  ADD_DEPARTMENT = "addDepartment",
  UPDATE_DEPARTMENT = "updateDepartment",
  REMOVE_DEPARTMENT = "removeDepartment",
  SEASON_LIST = "seasonList",
  ADD_SEASON = "addSeason",
  UPDATE_SEASON = "updateSeason",
  REMOVE_SEASON = "removeSeason",
  SUPPLIER_LIST = "supplierList",
  ADD_SUPPLIER = "addSupplier",
  UPDATE_SUPPLIER = "updateSupplier",
  REMOVE_SUPPLIER = "removeSupplier",
  ACCOUNT_LIST = "accountList",
  ADD_ACCOUNT = "addAccount",
  UPDATE_ACCOUNT = "updateAccount",
  REMOVE_ACCOUNT = "removeAccount",
  CHANGE_PASSWORD = "changePassword",
  ORDER_LIST = "orderList",
  ORDER_DETAIL = "orderDetail",
  ADD_ORDER_DETAIL = "addOrderDetail",
  UPDATE_ORDER_DETAIL = "updateOrderDetail",
  ADD_ORDER = "addOrder",
  ORDER_COPY = "orderCopy",
  ORDER_DELETE="orderDelete",
  UPDATE_ORDER = "updateOrder",
  REMOVE_ORDER = "removeOrder",
  REMOVE_ORDER_LINE = "removeOrderLine",
  REMOVE_FILE = "removeFile",
  CURRENCY_LIST = "currencyList",
  DELIVERY_LIST = "deliveryList",
  DELIVERY_LINE_LIST = "deliveryLineList",
  ADD_DELIVERY = "addDelivery",
  REMOVE_DELIVERY = "removeDelivery",
  UPDATE_DELIVERY = "updateDelivery",
  PERFORMANCE_REPORT_LIST="performanceReportList",
  LAST_SIX_MONTH_REPORT = "lastSixMonthReports",
  DIFFERENCE_REPORT_LIST= "differenceReportList",
  DELIVERY_REPORT_LIST = "deliveryReportList",
  ORDER_REPORT_LIST = "orderReportList",
  DELETED_ORDER_REPORT_LIST = "deletedOrderReportList",
  TRANSACTİON_LOG_LIST = "transactionLogList",
  SCREEN_GET_BY_ID = "screenGetById",
  SCREEN_LIST = "screenList",
  SCREEN_LIST_WITHOUT_CHILDREN = "screenListWithoutChildren",
  ACTION_LIST = "actionList",
  ADD_SCREEN = "addScreen",
  ADD_SCREEN_ACTION = "addScreenAction",
  DELETE_SCREEN = "deleteScreen",
  ROLE_LIST = "roleList",
  ROLE_SCREEN_LIST = "roleScreenList",
  ADD_ROLE_SCREEN = "addRoleScreen",
  ADD_ROLE = "addRole",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_PROVINCE = "setProvince",
  SET_COMPANY = "setCompany",
  SET_SIZE_SET = "setSizeSet",
  SET_DIFFERENCE_REASON = "setDifferenceReason",
  SET_CUSTOMER = "setCustomer",
  SET_DEPARTMENT = "setDepartment",
  SET_SEASON = "setSeason",
  SET_SUPPLIER = "setSupplier",
  SET_ACCOUNT = "setAccount",
  SET_ORDER = "setOrder",
  SET_ORDER_DETAIL = "setOrderDetail",
  SET_CURRENCY = "setCurrency",
  SET_DELIVERY = "setDelivery",
  SET_DELIVERY_LINE="setDeliveryLine",
  SET_PERFORMANCE_REPORT = "setPerformanceReport",
  SET_DIFFERENCE_REPORT = "setDifferenceReport",
  SET_DELIVERY_REPORT = "setDeliveryReport",
  SET_ORDER_REPORT = "setOrderReport",
  SET_DELETED_ORDER_REPORT = "setDeletedOrderReport",
  SET_MONTH_REPORT = "setMonthReport",
  SET_TRANSACTION_LOG="setTransactionLog",
  SET_SCREEN="setScreen",
  SET_SCREEN_WITHOUT_CHILDREN = "setScreemWithoutChildren",
  SET_ACTION = "setAction",
  SET_ROLE = "setRole",
  SET_ROLE_SCREEN = "setRoleScreen",
  SET_BRAND = "setBrand",
  SET_ORDERTYPE = "setOrderType",
  SET_SHIPPINGTYPE = "setShippingType",
}

export { Actions, Mutations };
